.app {
  align-items: center;
  background-color: var(--color-zinc-200);
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

@media (prefers-color-scheme: dark) {
  .app {
    background-color: var(--color-zinc-800);
  }
}

@media (max-width: 875px) {
  .app {
    background-color: var(--color-black);
  }
}
