.status-pill {
  align-items: center;
  border-radius: 40px;
  display: flex;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  right: 32px;
  line-height: 17px;
  padding: 3px 8px 4px;
  position: absolute;
  user-select: none;
  top: 31px;
  z-index: 1;
}

.status-pill > *:first-child {
  margin-right: 8px;
}

.status-pill.is-live {
  background-color: var(--color-pill-red);
}

.status-pill.is-recorded {
  background-color: var(--color-pill-yellow);
  color: var(--color-black);
}

@media (max-width: 875px) and (orientation: portrait) {
  .status-pill {
    right: 24px;
  }
}
