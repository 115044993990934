.notification-container {
  display: flex;
  justify-content: center;
  margin-top: 34px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}

.notification-content {
  background: #cc422f;
  border-radius: 8px;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  max-width: 300px;
  padding: 8px 16px;
  width: 90%;
}

.notification-content > div {
  align-items: center;
  display: flex;
}

.notification-content svg {
  margin-right: 4px;
}

.notification-type {
  text-transform: capitalize;
}

@media (max-width: 875px) and (orientation: portrait) {
  .notification-container {
    margin-top: 66px;
  }
}
